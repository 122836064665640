<template>
  <div class="page-forum">
    <div class="forum-carousel">
      <carousel ref="carousel1" />
    </div>
    <div class="forum-new">
      <div class="new-titlt">最新图片</div>
      <div class="new-content">
        <div class="new-img">
          <newCarousel />
        </div>
        <div class="new-lists">
          <tab />
        </div>
      </div>
    </div>
    <div class="forum-ranking">
      <div class="ranking-title">活跃排行旁</div>
      <ranking />
    </div>
    <div class="forum-carousel">
      <carousel ref="carousel2" />
    </div>
    <div class="forum-num">
      <div class="num-item" v-if="allNum">帖子:{{allNum.tiezi}}</div>
      <div class="num-item" v-if="allNum">会员:{{allNum.users}}</div>
      <div class="num-item new" v-if="allNum">
        欢迎新会员:
        <span>{{allNum.username}}</span>
      </div>
    </div>
    <div class="forum-deal">
      <div class="deal-title">买卖专区</div>
      <lxpList ref="mmzqs" />
    </div>
    <div class="forum-deal">
      <div class="deal-title">技术交流区</div>
      <lxpList ref="jsjls" />
    </div>
    <div class="forum-carousel">
      <carousel ref="carousel3" />
    </div>
    <div class="forum-lxp">
      <div class="lxp-title">聊天室</div>
      <lxpList ref="lts"></lxpList>
    </div>
    <div class="forum-lxp">
      <div class="lxp-title">休闲娱乐区</div>
      <lxpList ref="ylxxs"></lxpList>
    </div>
    <div class="forum-lxp">
      <div class="lxp-title">品牌官方讨论区</div>
      <lxpList ref="gfpps"></lxpList>
    </div>
    <div class="forum-opinion">
      <div class="opinion-title">意见与投诉</div>
      <lxpList ref="yjfks"></lxpList>
    </div>
    <div class="forum-opinion">
      <div class="opinion-title">论坛管理区</div>
      <lxpList ref="gls"></lxpList>
    </div>
  </div>
</template>
<script>
import carousel from './componets/carousel';
import newCarousel from '../componets/newCarousel';
import tab from '../componets/tab';
import ranking from './componets/ranking';
import areaList from './componets/areaList';
import lxpList from './componets/lxpList';
export default {
  components: { carousel, newCarousel, tab, ranking, areaList, lxpList },
  data() {
    return {
      allNum: {},
    };
  },
  created() {
    this.getAllList();
    this.getALLNum();
    this.getcarousel();
  },
  methods: {
    getcarousel() {
      //ref = 'carousel1';
      return this.$request(Apis.forum.LTSYIMGS)
        .then(data => {
          console.log('aggagag ', data);
          this.$nextTick(() => {
            setTimeout(() => {
              this.$refs.carousel1.carouselLlist = data.data.alterImg1;
              this.$refs.carousel2.carouselLlist = data.data.alterImg2;
              this.$refs.carousel3.carouselLlist = data.data.alterImg3;
            }, 10);
          });
        })
        .catch(e => {});
    },
    callback(key) {},
    getAllList() {
      return this.$request(Apis.forum.LTTZRES)
        .then(data => {
          this.$nextTick(() => {
            setTimeout(() => {
              this.getYlxxs(data.data.ylxxs);
              this.getyjfks(data.data.yjfks);
              this.getlts(data.data.lts);
              this.getjsjls(data.data.jsjls);
              this.getgls(data.data.gls);
              this.getgfpps(data.data.gfpps);
              this.getmmzqs(data.data.mmzqs);
            }, 10);
          });
        })
        .catch(e => {})
        .finally(() => {});
    },
    getYlxxs(list) {
      let listsid = [];
      list.forEach(e => {
        listsid.push(+e.id);
      });
      this.$request(Apis.forum.DYTUPIAN, { data: listsid })
        .then(data => {
          let dataList = data.data.dytp;
          let imgList = [];
          for (let i = 0; i < list.length; i++) {
            let object = list[i];
            if (object.id) {
              for (let j = 0; j < dataList.length; j++) {
                if (i === j) {
                  object.thumb = dataList[j];
                }
              }
              imgList.push(object);
            }
          }
          this.$refs.ylxxs.list = imgList;
        })
        .catch(e => {})
        .finally(() => {});
    },
    getyjfks(list) {
      let listsid = [];
      list.forEach(e => {
        listsid.push(+e.id);
      });
      this.$request(Apis.forum.DYTUPIAN, { data: listsid })
        .then(data => {
          let dataList = data.data.dytp;
          let imgList = [];
          for (let i = 0; i < list.length; i++) {
            let object = list[i];
            if (object.id) {
              for (let j = 0; j < dataList.length; j++) {
                if (i === j) {
                  object.thumb = dataList[j];
                }
              }
              imgList.push(object);
            }
          }
          this.$refs.yjfks.list = imgList;
        })
        .catch(e => {})
        .finally(() => {});
    },
    getlts(list) {
      let listsid = [];
      list.forEach(e => {
        listsid.push(+e.id);
      });
      this.$request(Apis.forum.DYTUPIAN, { data: listsid })
        .then(data => {
          let dataList = data.data.dytp;
          let imgList = [];
          for (let i = 0; i < list.length; i++) {
            let object = list[i];
            if (object.id) {
              for (let j = 0; j < dataList.length; j++) {
                if (i === j) {
                  object.thumb = dataList[j];
                }
              }
              imgList.push(object);
            }
          }
          this.$refs.lts.list = imgList;
        })
        .catch(e => {})
        .finally(() => {});
    },
    getjsjls(list) {
      let listsid = [];
      list.forEach(e => {
        listsid.push(+e.id);
      });
      this.$request(Apis.forum.DYTUPIAN, { data: listsid })
        .then(data => {
          let dataList = data.data.dytp;
          let imgList = [];
          for (let i = 0; i < list.length; i++) {
            let object = list[i];
            if (object.id) {
              for (let j = 0; j < dataList.length; j++) {
                if (i === j) {
                  object.thumb = dataList[j];
                }
              }
              imgList.push(object);
            }
          }
          this.$refs.jsjls.list = imgList;
        })
        .catch(e => {})
        .finally(() => {});
    },
    getgls(list) {
      let listsid = [];
      list.forEach(e => {
        listsid.push(+e.id);
      });
      this.$request(Apis.forum.DYTUPIAN, { data: listsid })
        .then(data => {
          let dataList = data.data.dytp;
          let imgList = [];
          for (let i = 0; i < list.length; i++) {
            let object = list[i];
            if (object.id) {
              for (let j = 0; j < dataList.length; j++) {
                if (i === j) {
                  object.thumb = dataList[j];
                }
              }
              imgList.push(object);
            }
          }
          this.$refs.gls.list = imgList;
        })
        .catch(e => {})
        .finally(() => {});
    },
    getgfpps(list) {
      let listsid = [];
      list.forEach(e => {
        listsid.push(+e.id);
      });
      this.$request(Apis.forum.DYTUPIAN, { data: listsid })
        .then(data => {
          let dataList = data.data.dytp;
          let imgList = [];
          for (let i = 0; i < list.length; i++) {
            let object = list[i];
            if (object.id) {
              for (let j = 0; j < dataList.length; j++) {
                if (i === j) {
                  object.thumb = dataList[j];
                }
              }
              imgList.push(object);
            }
          }
          this.$refs.gfpps.list = imgList;
        })
        .catch(e => {})
        .finally(() => {});
    },
    getmmzqs(list) {
      let listsid = [];
      list.forEach(e => {
        listsid.push(+e.id);
      });
      this.$request(Apis.forum.DYTUPIAN, { data: listsid })
        .then(data => {
          let dataList = data.data.dytp;
          let imgList = [];
          for (let i = 0; i < list.length; i++) {
            let object = list[i];
            if (object.id) {
              for (let j = 0; j < dataList.length; j++) {
                if (i === j) {
                  object.thumb = dataList[j];
                }
              }
              imgList.push(object);
            }
          }
          this.$refs.mmzqs.list = imgList;
        })
        .catch(e => {})
        .finally(() => {});
    },
    getALLNum() {
      return this.$request(Apis.forum.ZONGSHU)
        .then(data => {
          this.allNum = data.data;
        })
        .catch(e => {})
        .finally(() => {});
    },
  },
};
</script>
<style lang="less" scoped>
.page-forum {
  .forum-new {
    width: 100%;
    margin: 30px 0;
    padding: 0 50px;

    .new-titlt {
      font-size: 18px;
      color: #ccc;
      font-weight: 600;
      margin-bottom: 10px;
    }
    .new-content {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .new-img {
        width: 40%;
      }
      .new-lists {
        width: 50%;
      }
    }
  }
  .forum-ranking {
    width: 100%;
    padding: 0 40px;
    margin-bottom: 30px;
    .ranking-title {
      padding: 0 10px;
      font-size: 18px;
      font-weight: 600;
      color: #ccc;
      margin-bottom: 10px;
    }
  }
  .forum-num {
    display: flex;
    justify-content: flex-start;
    padding: 0 50px;
    color: #ccc;
    margin: 20px 0;
    .num-item {
      padding-right: 10px;
    }
    .new {
      span {
        font-weight: 600;
      }
    }
  }
  .forum-deal {
    padding: 0 50px;
    .deal-title {
      padding: 0 10px;
      font-size: 18px;
      font-weight: 600;
      color: #ccc;
      margin-bottom: 10px;
    }
  }
  .forum-lxp {
    padding: 0 50px;
    .lxp-title {
      padding: 10px 0;
      font-size: 18px;
      font-weight: 600;
      color: #ccc;
    }
  }
  .forum-opinion {
    padding: 0 50px;
    .opinion-title {
      padding: 10px 0;
      font-size: 18px;
      font-weight: 600;
      color: #ccc;
    }
    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #ccc;
      .item-left {
        width: 50%;
        display: flex;
        align-items: center;
        .img {
          width: 30%;

          position: relative;
          .bg {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            margin: auto;
            overflow: hidden;
            background: #ccc;
          }
          .holder {
            padding-bottom: 60%;
          }
        }
        .item-title {
          padding: 5px 30px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
}
</style>
