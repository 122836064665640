var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ranking-list" },
    _vm._l(_vm.lists, function(item) {
      return _c("div", { key: item.id, staticClass: "ranking-item" }, [
        _c("div", { staticClass: "item-bg" }, [
          _c("div", {
            staticClass: "img",
            style: {
              backgroundImage: "url(" + item.file + ")",
              backgroundSize: "cover"
            }
          }),
          _c("div", { staticClass: "holder" })
        ]),
        _c("div", { staticClass: "item-name" }, [_vm._v(_vm._s(item.username))])
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }