var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-forum" }, [
    _c(
      "div",
      { staticClass: "forum-carousel" },
      [_c("carousel", { ref: "carousel1" })],
      1
    ),
    _c("div", { staticClass: "forum-new" }, [
      _c("div", { staticClass: "new-titlt" }, [_vm._v("最新图片")]),
      _c("div", { staticClass: "new-content" }, [
        _c("div", { staticClass: "new-img" }, [_c("newCarousel")], 1),
        _c("div", { staticClass: "new-lists" }, [_c("tab")], 1)
      ])
    ]),
    _c(
      "div",
      { staticClass: "forum-ranking" },
      [
        _c("div", { staticClass: "ranking-title" }, [_vm._v("活跃排行旁")]),
        _c("ranking")
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "forum-carousel" },
      [_c("carousel", { ref: "carousel2" })],
      1
    ),
    _c("div", { staticClass: "forum-num" }, [
      _vm.allNum
        ? _c("div", { staticClass: "num-item" }, [
            _vm._v("帖子:" + _vm._s(_vm.allNum.tiezi))
          ])
        : _vm._e(),
      _vm.allNum
        ? _c("div", { staticClass: "num-item" }, [
            _vm._v("会员:" + _vm._s(_vm.allNum.users))
          ])
        : _vm._e(),
      _vm.allNum
        ? _c("div", { staticClass: "num-item new" }, [
            _vm._v("\n      欢迎新会员:\n      "),
            _c("span", [_vm._v(_vm._s(_vm.allNum.username))])
          ])
        : _vm._e()
    ]),
    _c(
      "div",
      { staticClass: "forum-deal" },
      [
        _c("div", { staticClass: "deal-title" }, [_vm._v("买卖专区")]),
        _c("lxpList", { ref: "mmzqs" })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "forum-deal" },
      [
        _c("div", { staticClass: "deal-title" }, [_vm._v("技术交流区")]),
        _c("lxpList", { ref: "jsjls" })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "forum-carousel" },
      [_c("carousel", { ref: "carousel3" })],
      1
    ),
    _c(
      "div",
      { staticClass: "forum-lxp" },
      [
        _c("div", { staticClass: "lxp-title" }, [_vm._v("聊天室")]),
        _c("lxpList", { ref: "lts" })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "forum-lxp" },
      [
        _c("div", { staticClass: "lxp-title" }, [_vm._v("休闲娱乐区")]),
        _c("lxpList", { ref: "ylxxs" })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "forum-lxp" },
      [
        _c("div", { staticClass: "lxp-title" }, [_vm._v("品牌官方讨论区")]),
        _c("lxpList", { ref: "gfpps" })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "forum-opinion" },
      [
        _c("div", { staticClass: "opinion-title" }, [_vm._v("意见与投诉")]),
        _c("lxpList", { ref: "yjfks" })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "forum-opinion" },
      [
        _c("div", { staticClass: "opinion-title" }, [_vm._v("论坛管理区")]),
        _c("lxpList", { ref: "gls" })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }