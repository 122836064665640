<template>
  <div class="lxp-list">
    <template v-if="list.length !== 0">
      <div class="lxp-item" v-for="(item, index) in list" :key="index" @click="btnlxp(item.id)">
        <div class="item-bg">
          <div
            class="img"
            :style="{ backgroundImage: 'url(' + item.thumb + ')', backgroundSize: 'cover' }"
          ></div>
          <div class="holder"></div>
        </div>
        <div class="item-details">
          <div class="details-item">标题:{{item.title}}</div>
          <div class="details-item">作者:{{item.username}}</div>
          <div class="details-item">发表:{{ $moment(+item.create_time).format('YYYY-MM-DD HH:mm') }}</div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="no">此版块暂无内容</div>
    </template>
  </div>
</template>
<script>
export default {
  data() {
    return { list: [] };
  },
  methods: {
    btnlxp(id) {
      this.$router.push('/forum/details/' + id);
    },
  },
};
</script>
<style lang="less" scoped>
.lxp-list {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  position: relative;
  flex-wrap: wrap;
  .lxp-item {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 33.3%;
    padding-right: 10px;
    position: relative;
    margin-bottom: 30px;
    .item-bg {
      width: 35%;
      position: relative;
      .img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #ccc;
        overflow: hidden;
        margin: auto;
      }
      .holder {
        padding-bottom: 100%;
      }
    }
    .item-details {
      width: 60%;
      .details-item:first-child {
        font-size: 14px;
        color: #fff;
      }
      .details-item {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #ccc;
        padding: 5px 0;
        font-size: 12px;
      }
    }
  }
  .no {
    font-size: 28px;
    width: 100%;
    text-align: center;
    margin: 30px 0;
    color: #fff;
  }
}
</style>
