<template>
  <div class="area-list">
    <div class="item" v-for="(item,index) in list" :key="index">
      <div class="item-left">
        <div class="img">
          <div
            class="bg"
            :style="{ backgroundImage: 'url(' + item.thumb + ')', backgroundSize: 'cover' }"
          ></div>
          <div class="holder"></div>
        </div>
        <div class="item-title">{{item.title}}</div>
      </div>
      <div class="item-right">
        <div>{{item.username}}</div>
        <div>{{ $moment(+item.create_time).format('YYYY-MM-DD HH:mm') }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import VueTypes from 'vue-types';
export default {
  data() {
    return { list: [] };
  },
  mounted() {
    
  },
};
</script>
<style lang="less" scoped>
.area-list {
  margin-bottom: 20px;
  .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #ccc;
    .item-left {
      width: 50%;
      display: flex;
      align-items: center;
      .img {
        width: 30%;

        position: relative;
        .bg {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          margin: auto;
          overflow: hidden;
          background: #ccc;
        }
        .holder {
          padding-bottom: 60%;
        }
      }
      .item-title {
        padding: 0 30px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .item-right {
      width: 30%;
      text-align: right;

      div {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
</style>