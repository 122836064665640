<template>
  <div class="homeCarousel" id="qqq">
    <a-carousel autoplay>
      <template v-for="item in carouselLlist">
        <a :href="item.link_url" :key="item.id">
          <div
            class="homeCarousel-bg"
            :style="{ backgroundImage: 'url(' + item.img_src + ')', backgroundSize: 'cover' }"
          ></div>
        </a>
      </template>
    </a-carousel>
  </div>
</template>
<script>
import VueTypes from 'vue-types';
export default {
  data() {
    return {
      carouselLlist: [],
    };
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>
<style lang="less">
#qqq .slick-dots {
  bottom: 30px;
  li {
    margin: 0 10px;
    button {
      width: 15px !important;
      height: 15px;
      border-radius: 50% !important;
    }
  }
}
</style>
<style lang="less" scoped>
.homeCarousel {
  .ant-carousel {
    height: 100%;

    .slick-initialized {
      height: 100%;
      .slick-list {
        height: 100%;
      }
      .slick-slide {
        height: 100%;
        .homeCarousel-bg {
          background: #eee;
          padding-bottom: 30%;
          background-size: cover;
        }
        .homeCarousel-title {
          text-align: center;
          margin: 0;
          height: 50px;
          line-height: 50px;
          font-size: 17px;
          font-weight: 600;
          color: #fff;
        }
      }
    }
  }
}
</style>
