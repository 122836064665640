<template>
  <div class="ranking-list">
    <div class="ranking-item" v-for="item in lists" :key="item.id">
      <div class="item-bg">
        <div class="img" :style="{ backgroundImage: 'url(' + item.file + ')', backgroundSize: 'cover' }"></div>
        <div class="holder"></div>
      </div>
      <div class="item-name">{{item.username}}</div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      lists: [],
    };
  },
  mounted() {
    this.getRank();
  },
  methods: {
    getRank() {
      return this.$request(Apis.forum.HYDS)
        .then(data => {
          this.lists=data.data.data
          
        })
        .catch(e=>{})
        .finally(() => {});
    },
  },
};
</script>
<style lang="less" scoped>
.ranking-list {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  .ranking-item {
    width: 12.5%;
    padding: 0 10px;
    position: relative;
    margin-bottom: 10px;
    .item-bg {
      position: relative;
      width: 100%;

      .img {
        position: absolute;
        top: 0;
        left: 0;
        margin: auto;
        overflow: hidden;
        width: 100%;
        height: 100%;
        background: #ccc;
      }
      .holder {
        pointer-events: none;
        visibility: hidden;
        display: block;
        padding-bottom: 100%;
      }
    }
    .item-name {
      padding: 5px 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .item-name,
    .item-num {
      text-align: center;
      color: #ccc;
    }
  }
}
</style>