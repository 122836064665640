var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "area-list" },
    _vm._l(_vm.list, function(item, index) {
      return _c("div", { key: index, staticClass: "item" }, [
        _c("div", { staticClass: "item-left" }, [
          _c("div", { staticClass: "img" }, [
            _c("div", {
              staticClass: "bg",
              style: {
                backgroundImage: "url(" + item.thumb + ")",
                backgroundSize: "cover"
              }
            }),
            _c("div", { staticClass: "holder" })
          ]),
          _c("div", { staticClass: "item-title" }, [_vm._v(_vm._s(item.title))])
        ]),
        _c("div", { staticClass: "item-right" }, [
          _c("div", [_vm._v(_vm._s(item.username))]),
          _c("div", [
            _vm._v(
              _vm._s(_vm.$moment(+item.create_time).format("YYYY-MM-DD HH:mm"))
            )
          ])
        ])
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }