var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "lxp-list" },
    [
      _vm.list.length !== 0
        ? _vm._l(_vm.list, function(item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "lxp-item",
                on: {
                  click: function($event) {
                    return _vm.btnlxp(item.id)
                  }
                }
              },
              [
                _c("div", { staticClass: "item-bg" }, [
                  _c("div", {
                    staticClass: "img",
                    style: {
                      backgroundImage: "url(" + item.thumb + ")",
                      backgroundSize: "cover"
                    }
                  }),
                  _c("div", { staticClass: "holder" })
                ]),
                _c("div", { staticClass: "item-details" }, [
                  _c("div", { staticClass: "details-item" }, [
                    _vm._v("标题:" + _vm._s(item.title))
                  ]),
                  _c("div", { staticClass: "details-item" }, [
                    _vm._v("作者:" + _vm._s(item.username))
                  ]),
                  _c("div", { staticClass: "details-item" }, [
                    _vm._v(
                      "发表:" +
                        _vm._s(
                          _vm
                            .$moment(+item.create_time)
                            .format("YYYY-MM-DD HH:mm")
                        )
                    )
                  ])
                ])
              ]
            )
          })
        : [_c("div", { staticClass: "no" }, [_vm._v("此版块暂无内容")])]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }